import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import Station from '../../../models/Station';

export interface GatewayIPModalState {
    isOpen: boolean;
    station?: Pick<Station, 'id' | 'gateway' | 'propertyId' | 'serialNumber'>;
}

const initialState: GatewayIPModalState = {
    isOpen: false,
};

export const slice = createSlice({
    name: 'gatewayIPModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setStation: (
            state,
            action: PayloadAction<
                | Pick<
                      Station,
                      'id' | 'gateway' | 'propertyId' | 'serialNumber'
                  >
                | null
                | undefined
            >
        ) => {
            if (!action.payload) return;
            state.station = action.payload;
        },
    },
});

export const { setOpen, setStation } = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

export const selectIsOpen = (state: RootState) => state.gatewayIPModal.isOpen;
export const selectStation = (state: RootState) => state.gatewayIPModal.station;
