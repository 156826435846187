enum StationStatus {
    AVAILABLE = 'AVAILABLE',
    FINISHING = 'FINISHING',
    PREPARING = 'PREPARING',
    FAULTED = 'FAULTED',
    SUSPENDEDEV = 'SUSPENDEDEV',
    CHARGING = 'CHARGING',
    UNAVAILABLE = 'UNAVAILABLE',
    DISCONNECTED = 'DISCONNECTED',
    UNHEALTHY = 'UNHEALTHY',
    CONNECTED = 'CONNECTED',
}

// Cast into any cause we're not targeting ES2017
// https://stackoverflow.com/questions/43804805/check-if-value-exists-in-enum-in-typescript
export const possibleStatuses = (<any>Object).values(StationStatus).map((status: string) => status.toLowerCase());

export default StationStatus;
