import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface CalloutInterface {
    message: string;
    color?: string;
    textColor?: string;
    bgColor?: string;
}

export interface CalloutState {
    callout?: CalloutInterface;
}

const initialState: CalloutState = {
    callout: undefined,
};

export const slice = createSlice({
    name: 'callout',
    initialState,
    reducers: {
        addCallout: (state, action: PayloadAction<CalloutInterface>) => {
            state.callout = action.payload;
        },
        clearCallout: (state) => {
            state.callout = undefined;
        },
    },
});

export const { addCallout, clearCallout } = slice.actions;
export const actions = slice.actions;

export const selectCallout = (state: RootState) => state.callout.callout;

export default slice.reducer;
