import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import layout from './containers/Layout/reducer';
import loading from './containers/Loading/reducer';
import disconnectedStations from './containers/DisconnectedStationsPage/reducer';
import gatewaysHealth from './containers/RouterMgmtPage/reducer';
import locationModal from './components/modals/EditLocationModal/slice';
import panelModal from './components/modals/EditPanelModal/slice';
import clusterModal from './components/modals/EditClusterModal/slice';
import toaster from './components/Toaster/slice';
import vehicleModal from './components/modals/EditVehicleModal/slice';
import callout from './components/Callout/slice';
import rfidModal from './components/modals/EditRFIDModal/slice';
import userModal from './components/modals/EditUserModal/slice';
import gatewayIPModal from './components/modals/EditGatewayIPAddressModal/slice';

const store = configureStore({
    reducer: {
        layout,
        loading,
        disconnectedStations,
        gatewaysHealth,
        locationModal,
        panelModal,
        clusterModal,
        toaster,
        vehicleModal,
        callout,
        rfidModal,
        userModal,
        gatewayIPModal,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;
