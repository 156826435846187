import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import Location from '../../../models/Location';

export interface LocationModalState {
    isOpen: boolean;
    propertyId: string;
    initialLocation?: Location | null;
}

const initialState: LocationModalState = {
    isOpen: false,
    propertyId: '',
};

export const slice = createSlice({
    name: 'locationModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setPropertyId: (state, action: PayloadAction<string>) => {
            state.propertyId = action.payload;
        },
        setInitialLocation: (
            state,
            action: PayloadAction<Location | null | undefined>
        ) => {
            state.initialLocation = action.payload;
        },
    },
});

export const { setOpen, setPropertyId, setInitialLocation } = slice.actions;
export const actions = slice.actions;

export const selectIsOpen = (state: RootState) => state.locationModal.isOpen;
export const selectPropertyId = (state: RootState) =>
    state.locationModal.propertyId;
export const selectInitialLocation = (state: RootState) =>
    state.locationModal.initialLocation;

export default slice.reducer;
