import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import Vehicle from '../../../models/Vehicle';

export interface VehicleModalState {
    isOpen: boolean;
    initialVehicle?: Vehicle | null;
}

const initialState: VehicleModalState = {
    isOpen: false,
};

export const slice = createSlice({
    name: 'vehicleModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setInitialVehicle: (
            state,
            action: PayloadAction<Vehicle | null | undefined>
        ) => {
            if (!action.payload) return;
            const vehicle = {
                ...action.payload,
                organizationId: action.payload?.organization?.id,
                propertyId: action.payload?.property?.id,
                locationId: action.payload?.location?.id,
            };
            state.initialVehicle = vehicle;
        },
        clearInitialVehicle: (state) => {
            state.initialVehicle = undefined;
        },
    },
});

export const { setOpen, setInitialVehicle, clearInitialVehicle } =
    slice.actions;
export const actions = slice.actions;

export default slice.reducer;

export const selectIsOpen = (state: RootState) => state.vehicleModal.isOpen;
export const selectNetworkOrganizations = (state: RootState) =>
    state.layout.network.organizations;
export const selectInitialVehicle = (state: RootState) =>
    state.vehicleModal.initialVehicle;
