import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import RFID from '../../../models/RFID';
import User from '../../../models/User';
import Vehicle from '../../../models/Vehicle';

export interface RFIDModalState {
    isOpen: boolean;
    initialRFID?: RFID | null;
    users?: Array<User>;
    vehicles?: Array<Vehicle>;
}

const initialState: RFIDModalState = {
    isOpen: false,
};

export const slice = createSlice({
    name: 'rfidModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setInitialRFID: (
            state,
            action: PayloadAction<RFID | null | undefined>
        ) => {
            if (!action.payload) return;
            const rfid = {
                ...action.payload,
                organizationId: action.payload.organization?.id,
                propertyId: action.payload.property?.id || '',
                locationId: action.payload.location?.id,
                vehicleId: action.payload.vehicle?.id,
            };
            state.initialRFID = rfid;
        },
        clearInitialRFID: (state) => {
            state.initialRFID = undefined;
        },
        setUsers: (
            state,
            action: PayloadAction<Array<User> | null | undefined>
        ) => {
            if (!action.payload) return;
            state.users = action.payload;
        },
        setVehicles: (
            state,
            action: PayloadAction<Array<Vehicle> | null | undefined>
        ) => {
            if (!action.payload) return;
            state.vehicles = action.payload;
        },
    },
});

export const {
    setOpen,
    setInitialRFID,
    clearInitialRFID,
    setUsers,
    setVehicles,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

export const selectIsOpen = (state: RootState) => state.rfidModal.isOpen;
export const selectNetworkOrganizations = (state: RootState) =>
    state.layout.network.organizations;
export const selectInitialRFID = (state: RootState) =>
    state.rfidModal.initialRFID;
export const selectUsers = (state: RootState) => state.rfidModal.users;
export const selectVehicles = (state: RootState) => state.rfidModal.vehicles;
