import { CToast, CToastBody, CToastHeader, CToaster } from '@coreui/react';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { Toast, selectToast } from './slice';

function Toaster() {
    const [toast, addToast] = useState<any>(0);
    const toaster = useRef<any>();
    const newToast = useAppSelector(selectToast);

    useEffect(() => {
        if (newToast) addToast(Toast(newToast));
    }, [newToast]);

    const Toast = ({ title, message, color }: Toast) => (
        <CToast>
            <CToastHeader closeButton>
                <svg
                    className="rounded me-2"
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false"
                    role="img"
                >
                    <rect
                        width="100%"
                        height="100%"
                        fill={color ?? '#007aff'}
                    ></rect>
                </svg>
                <div className="fw-bold me-auto">{title}</div>
            </CToastHeader>
            {message && <CToastBody>{message}</CToastBody>}
        </CToast>
    );
    return (
        <CToaster
            className="p-3"
            placement="top-end"
            push={toast}
            ref={toaster}
        />
    );
}

export default Toaster;
