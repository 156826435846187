import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface Toast {
    title: string;
    message: string;
    color?: string;
}

export interface LocationModalState {
    toast?: Toast;
}

const initialState: LocationModalState = {
    toast: undefined,
};

export const slice = createSlice({
    name: 'toaster',
    initialState,
    reducers: {
        addToast: (state, action: PayloadAction<Toast>) => {
            state.toast = action.payload;
        },
    },
});

export const { addToast } = slice.actions;
export const actions = slice.actions;

export const selectToast = (state: RootState) => state.toaster.toast;

export default slice.reducer;
