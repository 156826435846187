import { DateTime } from "luxon";
import StationStatus from "../../models/enums/StationStatus";
import Station from "../../models/Station";
import StationHealth from "../../models/StationHealth";

const calculateStationStatus = (stations: Array<StationHealth>, station: Pick<Station, 'serialNumber'>) => {
    let status = StationStatus.AVAILABLE;
    const stationHealth = stations.find((info) =>
            info.serial ===
            station.serialNumber
    );
    if (stationHealth) {
        if (stationHealth.status) {
            status = StationStatus[
                stationHealth.status as keyof typeof StationStatus
            ];
        }
    }

    const now = DateTime.now();
    const disconnectedTime =
    now.diff(
        DateTime.fromISO(
            stationHealth?.lastCommunication ||
                '2000-01-01'
        ).setZone(
            'America/Los_Angeles'
        ),
        ['minutes']
    ).minutes;

    if (disconnectedTime > 5) {
        status = StationStatus.DISCONNECTED;
    } else if (disconnectedTime > 2) {
        status = StationStatus.UNHEALTHY;
    }
    return status;
}

export default calculateStationStatus;
