import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import Cluster from '../../../models/Cluster';
import Property from '../../../models/Property';

export interface ClusterModalState {
    isOpen: boolean;
    propertyId: string;
    locationId?: string | null;
    panelId?: string | null;
    property?: Property;
    initialCluster?: Cluster | null;
}

const initialState: ClusterModalState = {
    isOpen: false,
    propertyId: '',
    locationId: '',
    panelId: '',
    property: {} as Property,
};

export const slice = createSlice({
    name: 'clusterModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setPropertyId: (state, action: PayloadAction<string>) => {
            state.propertyId = action.payload;
        },
        setLocationId: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.locationId = action.payload;
        },
        setPanelId: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.panelId = action.payload;
        },
        setProperty: (state, action: PayloadAction<Property>) => {
            state.property = action.payload;
        },
        setInitialCluster: (
            state,
            action: PayloadAction<Cluster | null | undefined>
        ) => {
            state.initialCluster = action.payload;
        },
    },
});

export const {
    setOpen,
    setPropertyId,
    setLocationId,
    setPanelId,
    setProperty,
    setInitialCluster,
} = slice.actions;
export const actions = slice.actions;

export const selectIsOpen = (state: RootState) => state.clusterModal.isOpen;
export const selectPropertyId = (state: RootState) =>
    state.clusterModal.propertyId;
export const selectLocationId = (state: RootState) =>
    state.clusterModal.locationId;
export const selectPanelId = (state: RootState) => state.clusterModal.panelId;
export const selectProperty = (state: RootState) => state.clusterModal.property;
export const selectInitialCluster = (state: RootState) =>
    state.clusterModal.initialCluster;

export default slice.reducer;
