import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StationStatus, {
    possibleStatuses,
} from '../../models/enums/StationStatus';
import Organization from '../../models/Organization';
import Property from '../../models/Property';
import Station from '../../models/Station';
import StationHealth from '../../models/StationHealth';
import calculateStationStatus from '../PropertyPage/calculateStationStatus';

export type NetworkPartialUpdate = Organization | Property | Station;

type SearchPayload = {
    search: string;
    stationsHealth: StationHealth[];
};

// Define a type for the slice state
interface LayoutState {
    showSidebar: boolean;
    showSidebarMobile: boolean;
    network: { organizations: Organization[] };
    filteredNetwork: { organizations: Organization[] };
    search: string;
}

// Define the initial state using that type
const initialState: LayoutState = {
    showSidebar: true,
    showSidebarMobile: false,
    network: { organizations: [] },
    filteredNetwork: { organizations: [] },
    search: '',
};

function filterObjectBySearch(
    object: object,
    search: string,
    stationsHealth?: StationHealth[]
) {
    const serialNumber = (<any>object).serialNumber;
    // Only filter by Station Status if specifically entered a possibleStatus, cause it might be slow
    if (possibleStatuses.includes(search) && !!serialNumber) {
        if (!!stationsHealth) {
            let status = calculateStationStatus(stationsHealth, {
                serialNumber,
            }) as StationStatus;
            if (status.toLowerCase() === search) return true;
        }
    }

    return Object.entries(object).find(([key, value]) => {
        if (key === 'id') {
            return value.toLowerCase() === search;
        }
        if (key === '__typename') {
            return false;
        }
        if (typeof value !== 'object') {
            return String(value).toLowerCase().includes(search);
        }
        return false;
    });
}

// TODO: the filter after every level will perform the filterObjectBySearch again
// Find a way to do it without calling it twice. Avoid setting a flag because that will
// be persisted into the redux state and potentially dirty future search results
function filterNetwork(
    network: { organizations: Organization[] },
    search: string,
    stationsHealth?: StationHealth[]
) {
    return {
        organizations: network.organizations
            .map((organization) => {
                // Return the whole org tree if it's matched
                if (filterObjectBySearch(organization, search))
                    return organization;

                // Check properties, but only return the properties that match
                const org = { ...organization };
                org.properties = org.properties
                    .map((property: any) => {
                        // Return the whole property tree if it's matched
                        if (filterObjectBySearch(property, search))
                            return property;

                        const prop = { ...property };
                        prop.clusters = property.clusters
                            .map((cluster: any) => {
                                if (filterObjectBySearch(cluster, search))
                                    return cluster;

                                const c = { ...cluster };
                                c.stations = cluster.stations.filter(
                                    (station: any) =>
                                        filterObjectBySearch(
                                            station,
                                            search,
                                            stationsHealth
                                        )
                                );

                                return c;
                            })
                            .filter(
                                (cluster: any) =>
                                    !search ||
                                    filterObjectBySearch(cluster, search) ||
                                    cluster.stations.length
                            );

                        prop.locations = property.locations
                            .map((location: any) => {
                                if (filterObjectBySearch(location, search))
                                    return location;

                                const loc = { ...location };
                                loc.clusters = location.clusters
                                    .map((cluster: any) => {
                                        if (
                                            filterObjectBySearch(
                                                cluster,
                                                search
                                            )
                                        )
                                            return cluster;

                                        const c = { ...cluster };
                                        c.stations = cluster.stations.filter(
                                            (station: any) =>
                                                filterObjectBySearch(
                                                    station,
                                                    search,
                                                    stationsHealth
                                                )
                                        );

                                        return c;
                                    })
                                    .filter(
                                        (cluster: any) =>
                                            !search ||
                                            filterObjectBySearch(
                                                cluster,
                                                search
                                            ) ||
                                            cluster.stations.length
                                    );

                                return loc;
                            })
                            .filter(
                                (location: any) =>
                                    !search ||
                                    filterObjectBySearch(location, search) ||
                                    location.clusters.length
                            );

                        return prop;
                    })
                    .filter(
                        (property: any) =>
                            !search ||
                            filterObjectBySearch(property, search) ||
                            property.clusters.length ||
                            property.locations.length
                    );

                return org;
            })
            .filter(
                (organization) =>
                    !search ||
                    filterObjectBySearch(organization, search) ||
                    organization.properties.length
            ),
    };
}

export const layoutSlice = createSlice({
    name: 'layout',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setShowSidebar: (state, action: PayloadAction<boolean>) => {
            state.showSidebar = action.payload;
        },
        setShowSidebarMobile: (state, action: PayloadAction<boolean>) => {
            state.showSidebarMobile = action.payload;
        },
        setNetwork: (
            state,
            action: PayloadAction<{ organizations: Organization[] }>
        ) => {
            state.network = action.payload;
            state.filteredNetwork = filterNetwork(
                state.network,
                state.search.toLowerCase()
            );
        },
        setSearch: (state, action: PayloadAction<SearchPayload>) => {
            state.search = action.payload.search;
            state.filteredNetwork = filterNetwork(
                state.network,
                state.search.toLowerCase(),
                action.payload.stationsHealth
            );
        },
        updateNetworkFromSubscription: (
            state,
            action: PayloadAction<NetworkPartialUpdate>
        ) => {
            const newNetwork = Object.assign({}, state.network);
            switch (action.payload.__typename) {
                case 'Organization':
                    const orgPayload = action.payload as Organization;
                    // New organizations do not have a properties list, make an empty one
                    if (!orgPayload.properties) {
                        orgPayload.properties = [];
                    }
                    const oIndex = newNetwork.organizations.findIndex(
                        (organization) => organization.id === orgPayload.id
                    );
                    if (oIndex > -1) {
                        newNetwork.organizations[oIndex] = {
                            ...newNetwork.organizations[oIndex],
                            ...orgPayload,
                        };
                    } else {
                        newNetwork.organizations.push(orgPayload);
                        newNetwork.organizations.sort((a, b) =>
                            a.name.localeCompare(b.name)
                        );
                    }
                    break;
                case 'Property':
                    const propPayload = action.payload as Property;
                    const poIndex = newNetwork.organizations.findIndex(
                        (organization) =>
                            organization.id === propPayload.organizationId
                    );
                    if (poIndex > -1) {
                        // TODO
                        // The action.payload for PropertySubbscription now returns Property.organization
                        // 2 different cases here:
                        // if property as payload already exists, update it as is currently done
                        // if property as payload doesn't exist, then add it to its Organization
                        const pIndex = newNetwork.organizations[
                            poIndex
                        ].properties.findIndex(
                            (property) => property.id === propPayload.id
                        );
                        if (pIndex === -1) {
                            // Push new property
                            newNetwork.organizations[poIndex].properties.push({
                                ...propPayload,
                                clusters: [],
                            });
                            // Sort by name
                            newNetwork.organizations[poIndex].properties.sort(
                                (a, b) => a.name.localeCompare(b.name)
                            );
                        } else {
                            // Update existing entry
                            newNetwork.organizations[poIndex].properties[
                                pIndex
                            ] = {
                                ...newNetwork.organizations[poIndex].properties[
                                    pIndex
                                ],
                                ...propPayload,
                            };
                        }
                    }
                    break;
                case 'Station':
                    const stationPayload = action.payload as Station;
                    const orgId = stationPayload.organizationId;
                    const spoIndex = newNetwork.organizations.findIndex(
                        (organization) => organization.id === orgId
                    );
                    if (spoIndex > -1) {
                        const org = newNetwork.organizations[spoIndex];
                        const propId = stationPayload.propertyId;
                        const spIndex = org.properties.findIndex(
                            (property) => property.id === propId
                        );
                        if (spIndex > -1) {
                            const prop = org.properties[spIndex];
                            const scIndex = prop.clusters.findIndex((cluster) =>
                                cluster.stations.find(
                                    (station) =>
                                        station.id === stationPayload.id
                                )
                            );
                            if (scIndex > -1) {
                                const sIndex = prop.clusters[
                                    scIndex
                                ].stations.findIndex(
                                    (station) =>
                                        station.id === stationPayload.id
                                );
                                if (sIndex === -1) {
                                    // Push new station
                                    newNetwork.organizations[
                                        spoIndex
                                    ].properties[spIndex].clusters[
                                        scIndex
                                    ].stations.push(stationPayload);
                                    // Filter by QR code
                                    newNetwork.organizations[
                                        spoIndex
                                    ].properties[spIndex].clusters[
                                        scIndex
                                    ].stations.sort((a, b) =>
                                        (a.qrCode || '').localeCompare(
                                            b.qrCode || ''
                                        )
                                    );
                                } else {
                                    // Update existing entry
                                    newNetwork.organizations[
                                        spoIndex
                                    ].properties[spIndex].clusters[
                                        scIndex
                                    ].stations[sIndex] = {
                                        ...newNetwork.organizations[spoIndex]
                                            .properties[spIndex].clusters[
                                            scIndex
                                        ].stations[sIndex],
                                        ...stationPayload,
                                    };
                                }
                            }
                        }
                    }
                    break;
            }
            state.network = newNetwork;
            state.filteredNetwork = filterNetwork(
                state.network,
                state.search.toLowerCase()
            );
        },
    },
});

export const {
    setShowSidebar,
    setShowSidebarMobile,
    setNetwork,
    setSearch,
    updateNetworkFromSubscription,
} = layoutSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectVisible = (state: RootState) => state.visible.value

export default layoutSlice.reducer;
