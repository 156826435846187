import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GatewayHealth from '../../models/GatewayHealth';
import StationGateway from '../../models/StationGateway';

// Define a type for the slice state
interface GatewaysHealthState {
    gateways: GatewayHealth[];
    stationGatewayList: StationGateway[];
}

// Define the initial state using that type
const initialState: GatewaysHealthState = {
    gateways: [],
    stationGatewayList: [],
};

export const gatewaysHealthSlice = createSlice({
    name: 'gatewaysHealth',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setGatewaysHealth: (state, action: PayloadAction<GatewayHealth[]>) => {
            state.gateways = action.payload;
        },
        setStationGatewayList: (
            state,
            action: PayloadAction<StationGateway[]>
        ) => {
            state.stationGatewayList = action.payload;
        },
    },
});

export const { setGatewaysHealth, setStationGatewayList } =
    gatewaysHealthSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectVisible = (state: RootState) => state.visible.value

export default gatewaysHealthSlice.reducer;
