import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import Panel from '../../../models/Panel';
import Property from '../../../models/Property';

export interface PanelModalState {
    isOpen: boolean;
    propertyId: string;
    locationId?: string | null;
    property?: Property;
    initialPanel?: Panel | null;
}

const initialState: PanelModalState = {
    isOpen: false,
    propertyId: '',
    locationId: '',
    property: {} as Property,
};

export const slice = createSlice({
    name: 'panelModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setPropertyId: (state, action: PayloadAction<string>) => {
            state.propertyId = action.payload;
        },
        setLocationId: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.locationId = action.payload;
        },
        setProperty: (state, action: PayloadAction<Property>) => {
            state.property = action.payload;
        },
        setInitialPanel: (
            state,
            action: PayloadAction<Panel | null | undefined>
        ) => {
            state.initialPanel = action.payload;
        },
    },
});

export const {
    setOpen,
    setPropertyId,
    setLocationId,
    setProperty,
    setInitialPanel,
} = slice.actions;
export const actions = slice.actions;

export const selectIsOpen = (state: RootState) => state.panelModal.isOpen;
export const selectPropertyId = (state: RootState) =>
    state.panelModal.propertyId;
export const selectLocationId = (state: RootState) =>
    state.panelModal.locationId;
export const selectProperty = (state: RootState) => state.panelModal.property;
export const selectInitialPanel = (state: RootState) =>
    state.panelModal.initialPanel;

export default slice.reducer;
