import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StationHealth from '../../models/StationHealth';

// Define a type for the slice state
interface DisconnectedStationsState {
    stations: StationHealth[];
}

// Define the initial state using that type
const initialState: DisconnectedStationsState = {
    stations: [],
};

export const disconnectedStationsSlice = createSlice({
    name: 'disconnectedStations',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setDisconnectedStations: (
            state,
            action: PayloadAction<StationHealth[]>
        ) => {
            state.stations = action.payload;
        },
    },
});

export const { setDisconnectedStations } = disconnectedStationsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectVisible = (state: RootState) => state.visible.value

export default disconnectedStationsSlice.reducer;
