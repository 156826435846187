import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import User from '../../../models/User';

export interface UserModalState {
    isOpen: boolean;
    initialUser?: User | null;
}

const initialState: UserModalState = {
    isOpen: false,
    initialUser: null,
};

export const slice = createSlice({
    name: 'userModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setInitialUser: (
            state,
            action: PayloadAction<User | null | undefined>
        ) => {
            state.initialUser = action.payload;
        },
    },
});

export const { setOpen, setInitialUser } = slice.actions;
export const actions = slice.actions;

export const selectIsOpen = (state: RootState) => state.userModal.isOpen;
export const selectInitialUser = (state: RootState) =>
    state.userModal.initialUser;

export default slice.reducer;
